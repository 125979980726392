<template>
    <OrderDelivery is-for-check-out :allowed-delivery-dates="allowedDeliveryDates"
        :is-calendar-and-delivery-times-shown="isRegularKitInCart">
        <template #unique-kits-names>
            {{ $t('unique_mealits.date_text') }}
            <!-- <div class="uniques-names__wrapper" v-if="isUniqueKitInCart">
                <h5 class="unique-name" v-for="uniqueKit in uniqueKitsInCart" :key="uniqueKit.id">
                    {{ $filters.getTranslationByKey(uniqueKit.mealKit.name) }} - {{ uniqueKit.mealKit.deliveryDate }}
                </h5>
            </div> -->
        </template>

        <template #unique-kits-dates v-if="isUniqueKitInCart">
            {{ $t('unique_mealits.date_text') }}
            <!-- <div class="uniques-dates__wrapper" v-for="uniqueKit in uniqueKitsInCart" :key="uniqueKit.id">
                <h5>{{ $filters.getTranslationByKey(uniqueKit.mealKit.name) }}</h5>
                <CRRadio class="radio" v-for="time in uniqueKit.mealKit.deliveryTimes" :key="time.id"
                    v-model="uniquesDatesVModelObject[time.mealKitId]" :value="time">
                    {{ getLabelForDeliveryTime(time) }}
                </CRRadio>
            </div> -->
        </template>

        <template #btn-section="{ deliveryData }">
            <div class="btn-section">
                <CRButton @click="$router.push({ name: 'checkOutBuyerInfo' })" pattern="outline">
                    {{ $t('buttons.back') }}
                </CRButton>
                <CRButton @click="toBuyerInfoPage(deliveryData)">
                    {{ $t('common.payment') }}
                </CRButton>
            </div>
        </template>
    </OrderDelivery>
</template>

<script>
import OrderDelivery from '@/components/Order/OrderDelivery.vue';
import { CalendarDaysApi } from '@/api';
import CRButton from '@/components/Common/Buttons/CRButton.vue';
// import CRRadio from '@/components/Common/Radio/CRRadio.vue';
import { mapActions, mapGetters } from 'vuex';
import { getLabelForDeliveryTime } from '@/helpers/DeliveryDateHelper';
import { useVuelidate } from '@vuelidate/core';

export default {
    name: 'Delivery',
    components: { OrderDelivery, CRButton },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            getLabelForDeliveryTime,
            allowedDeliveryDates: [],
            uniqueMealKitsDeliveryDates: [],
            uniquesDatesVModelObject: {},
        };
    },
    computed: {
        ...mapGetters('cart', ['cart', 'uniqueKitsInCart', 'isUniqueKitInCart', 'isRegularKitInCart']),
        ...mapGetters('orderInformation', ['deliveryInformation']),
    },
    methods: {
        ...mapActions('orderInformation', ['setDeliveryInformation']),

        async toBuyerInfoPage(deliveryData) {
            const result = await this.v$.$validate();
            if (!result) return;

            const data = this.prepareData(deliveryData);

            this.setDeliveryInformation(data);

            this.$router.push({ name: 'checkOutPayment' });
        },

        prepareData(deliveryData) {
            const data = {
                extraInformation: deliveryData.extraInformation,
                deliveryMethodId: deliveryData.deliveryMethodId,
                deliveryData: {},
            };

            if (this.isRegularKitInCart) {
                data.deliveryData.regular = {
                    deliveryTimeId: deliveryData.deliveryTimeId,
                    endTime: deliveryData.endTime,
                    deliveryStartDate: deliveryData.deliveryStartDate,
                    startTime: deliveryData.startTime,
                };
            }

            if (this.isUniqueKitInCart) {
                let uniques = [];
                Object.entries(this.uniquesDatesVModelObject).forEach((element) => {
                    uniques.push(element[1]);
                });

                data.deliveryData.uniques = uniques;
            }

            return data;
        },

        setInitialVModelState() {
            if (this.isUniqueKitInCart) {
                this.uniqueKitsInCart.forEach((element) => {
                    this.uniquesDatesVModelObject[element.mealKit.id] = element.mealKit.deliveryTimes[0];
                });
            }
        },

        setDataForCheckOutIfExistInState() {
            if (this.deliveryInformation?.deliveryData?.uniques?.length) {
                this.deliveryInformation.deliveryData.uniques.forEach((element) => {
                    this.uniquesDatesVModelObject[element.mealKitId] = element;
                });
            }
        },

        async getCalendarDays() {
            try {
                const response = await CalendarDaysApi.getAvailableDays();
                this.allowedDeliveryDates = response.data.map((calendarDay) => calendarDay.date);
            } catch (error) {
                this.$filters.toast(error.message);
            }
        },
    },
    async mounted() {
        await this.getCalendarDays();

        this.setInitialVModelState();

        this.setDataForCheckOutIfExistInState();
    },
};
</script>

<style lang="scss" scoped>
.uniques-names__wrapper {
    margin-bottom: 18px;

    .unique-name {
        margin-bottom: 5px;
    }
}

.uniques-dates__wrapper {
    margin-bottom: 18px;

    h5 {
        margin-bottom: 18px;
    }

    .radio {
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.btn-section {
    @include row-align-center-justify-between;
}
</style>
